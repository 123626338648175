<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Sellos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Sellos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div class="container-fluid">
          <div class="card">
            <div class="card-header pt-2 pb-2">
              <div class="row justify-content-between">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn bg-gradient-secondary border-secondary"
                    data-toggle="modal"
                    data-target="#modal-form-import"
                    style="cursor: pointer"
                    v-if="$store.getters.can('hidrocarburos.sellos.create')"
                  >
                    Importar Excel
                    <i class="fas fa-file-import"></i>
                  </button>
                </div>

                <div class="btn-group">
                  <button
                    type="button"
                    class="btn bg-gradient-dark border-dark"
                    data-toggle="modal"
                    data-target="#modal-form-rank"
                    style="cursor: pointer"
                    v-if="$store.getters.can('hidrocarburos.sellos.create')"
                    @click="$refs.SelloRangos.limpiarModal()"
                  >
                    Rangos de Sellos
                    <i class="fab fa-hackerrank"></i>
                  </button>
                </div>

                <div class="btn-group">
                  <div class="btn-group float-right">
                    <button
                      type="button"
                      class="btn bg-gradient-info border-info"
                      data-toggle="modal"
                      data-target="#modal_aside_right"
                      style="cursor: pointer"
                      v-if="$store.getters.can('hidrocarburos.sellos.create')"
                      @click="$refs.SelloMovimiento.limpiarDatos()"
                    >
                      Movimiento Sello
                      <i class="fas fa-people-carry"></i>
                    </button>
                  </div>
                </div>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn bg-gradient-info border-info"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                  <button
                    type="button"
                    class="btn bg-gradient-danger border-danger"
                    @click="resetFilter()"
                  >
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body pb-2">
              <div class="row justify-content-center">
                <div class="form-group col-md-2">
                  <label>Número de Sello</label>
                  <input
                    type="text"
                    v-model="filtros.numero"
                    placeholder="Numero de sello"
                    label="numero"
                    class="form-control form-control-sm"
                    @keyup.enter="getIndex()"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>N° Ticket</label>
                  <input
                    type="text"
                    v-model="filtros.ticket_id"
                    placeholder="N° Ticket"
                    label="ticket_id"
                    class="form-control form-control-sm"
                    @keyup.enter="getIndex()"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>N° Guía</label>
                  <input
                    type="text"
                    v-model="filtros.guia"
                    placeholder="N° Guía"
                    label="guia"
                    class="form-control form-control-sm"
                    @keyup.enter="getIndex()"
                  />
                </div>

                <div class="form-group col-md-5">
                  <label>Transportadora</label>
                  <v-select
                    v-model="slct_transportadora"
                    class="form-control form-control-sm p-0"
                    placeholder="Razón Social"
                    label="razon_social"
                    :options="listasForms.transportadoras"
                    @input="getIndex()"
                  ></v-select>
                </div>

                <div class="form-group col-md-3">
                  <label>Sitio</label>
                  <v-select
                    v-model="slct_sitio"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.sitios"
                    @input="getIndex()"
                  ></v-select>
                </div>
                <div class="form-group col-md-1">
                  <label>Vehículo</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="filtros.placa"
                    @keyup="getIndex()"
                  />
                </div>

                <div class="form-group col-md-1">
                  <label>Remolque</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="filtros.remolque"
                    @keyup="getIndex()"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label>Fecha</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="filtros.fecha"
                    @keyup="getIndex()"
                  />
                </div>

                <div>
                  <label>Estado</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="filtros.estado"
                    @change="getIndex()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estadoSello in listasForms.estados"
                      :key="estadoSello.numeracion"
                      :value="estadoSello.numeracion"
                    >
                      {{ estadoSello.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="card-body table-responsive p-0">
              <table
                id="sellos"
                class="table table-bordered table-striped table-hover table-sm text-nowrap"
                style="font-size: 0.85em"
              >
                <thead class="bg-dark">
                  <tr>
                    <th class="col-1">Número de sello</th>
                    <th class="col-1">Número de guia / ticket</th>
                    <th class="col-2">Transportadora</th>
                    <th>Sitio</th>
                    <th>Vehículo</th>
                    <th>Remolque</th>
                    <th>Destino</th>
                    <th>Fecha</th>
                    <th>Tipo Empresa</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in sellos.data" :key="item.id">
                    <td>
                      {{ item.numero }}
                    </td>

                    <td v-if="item.viaje && item.viaje.guia != null">
                      {{ item.viaje.guia.numero }}
                    </td>

                    <td v-else-if="item.viaje && item.viaje.ticket != null">
                      {{ item.viaje.ticket.numero_ticket }}
                    </td>

                    <td v-else>
                      {{ " " }}
                    </td>
                    <td>
                      {{
                        " " + item.viaje != null && item.viaje != undefined
                          ? item.viaje.transportadora.razon_social
                          : ""
                      }}
                    </td>
                    <td>
                      {{ item.sitio.nombre }}
                    </td>
                    <td>
                      {{
                        " " + item.viaje != null && item.viaje != undefined
                          ? item.viaje.vehiculo.placa
                          : ""
                      }}
                    </td>
                    <td>
                      {{
                        " " + item.viaje != null &&
                        item.viaje != undefined &&
                        item.viaje.remolque
                          ? item.viaje.remolque.placa
                          : ""
                      }}
                    </td>
                    <td>
                      {{
                        " " + item.viaje != null && item.viaje != undefined
                          ? item.viaje.sitio_descargue.nombre
                          : ""
                      }}
                    </td>
                    <td>
                      {{ item.created_at.slice(0, 10) }}
                    </td>
                    <td>
                      {{ item.tipoEmpresa }}
                    </td>
                    <td class="text-center">
                      <div v-if="item.estado == 1">
                        <h6>
                          <span
                            class="badge badge-success"
                            data-toggle="modal"
                            data-target="#modal-form-estado"
                            @click="llenarModal(item.id, item.estado)"
                            style="cursor: pointer"
                            ><i class="fas fa-check-circle"></i>
                            {{ item.estadoSello }}</span
                          >
                        </h6>
                      </div>
                      <div v-else-if="item.estado == 2">
                        <h6>
                          <span class="badge badge-primary"
                            ><i class="far fa-check-circle"></i>
                            {{ item.estadoSello }}</span
                          >
                        </h6>
                      </div>
                      <div v-else-if="item.estado == 3">
                        <h6>
                          <span class="badge badge-warning"
                            ><i class="fas fa-times-circle"></i>
                            {{ item.estadoSello }}</span
                          >
                        </h6>
                      </div>
                      <div v-else>
                        <h6>
                          <span class="badge badge-danger"
                            ><i class="far fa-times-circle"></i>
                            {{ item.estadoSello }}</span
                          >
                        </h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="float-left" v-if="sellos.total">
                <p>
                  Mostrando del <b>{{ sellos.from }}</b> al
                  <b>{{ sellos.to }}</b> de un total:
                  <b>{{ sellos.total }}</b> Registros
                </p>
              </div>
              <div class="float-left" v-else>
                <p>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </p>
              </div>
              <pagination
                :data="sellos"
                @pagination-change-page="getIndex"
                :limit="10"
                align="right"
              ></pagination>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="small-box bg-secondary">
                <div class="inner">
                  <h3 style="font-size: 50px">Sellos</h3>
                  <h3 style="font-size: 15px">
                    Disponibles:
                    <span class="badge badge-pill badge-success">{{
                      cantidadDisp
                    }}</span>
                  </h3>
                  <h3 style="font-size: 15px">
                    Usados:
                    <span class="badge badge-pill badge-primary">{{
                      cantidadUsa
                    }}</span>
                  </h3>
                  <h3 style="font-size: 15px">
                    Anulados:
                    <span class="badge badge-pill badge-warning">{{
                      cantidadAnu
                    }}</span>
                  </h3>
                  <h3 style="font-size: 15px">
                    Extraviados:
                    <span class="badge badge-pill badge-danger">{{
                      cantidadExt
                    }}</span>
                  </h3>
                </div>
                <div class="icon">
                  <i class="ion ion-pie-graph"></i>
                </div>
                <a href="#" class="small-box-footer"></a>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="small-box bg-light">
                <pie-chart
                  :data="chartData"
                  :options="chartOptions"
                ></pie-chart>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SelloEstado ref="SelloEstado" />
      <SelloImportExcel ref="SelloImportExcel" />
      <SelloRangos ref="SelloRangos" />
      <SelloMovimiento ref="SelloMovimiento" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import SelloEstado from "./SelloEstado";
import SelloImportExcel from "./SelloImportExcel";
import SelloRangos from "./SelloRangos";
import SelloMovimiento from "./SelloMovimiento";
import PieChart from "./../PieChart.js";
import Loading from "../../../../components/Loading";

export default {
  name: "Sellos",
  components: {
    Loading,
    pagination,
    vSelect,
    SelloEstado,
    SelloImportExcel,
    SelloRangos,
    SelloMovimiento,
    PieChart,
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      animation: {
        animateRotate: false,
        animateScale: true,
      },
      chartData: {
        hoverBorderWidth: 10,
        labels: ["Disponibles", "Usados", "Anulados", "Extraviados"],
        datasets: [
          {
            backgroundColor: ["#00C851", "#0099CC", "#ffbb33", "#ff4444"],
            borderWidth: 2,
          },
        ],
      },
      filtros: {},
      sitios: [],
      slct_sitio: [],
      slct_transportadora: [],
      sellos: {},
      listasForms: {
        transportadoras: [],
        estados: [],
        tipoEmpresas: [],
        sitios: [],
      },
      cargando: false,
      cantidadDisp: "",
      cantidadUsa: "",
      cantidadAnu: "",
      cantidadExt: "",
    };
  },
  methods: {
    addData: function addData() {
      this.dataset.push(this.dataentry);
      this.labels.push(this.datalabel);
      this.datalabel = "";
      this.dataentry = "";
    },

    getIndex(page = 1) {
      let me = this;
      me.cargando = true;
      this.filtros.transportadora_id = this.slct_transportadora
        ? this.slct_transportadora.id
        : null;
      this.filtros.sitio_id = this.slct_sitio ? this.slct_sitio.id : null;
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
      }
      axios
        .get("/api/hidrocarburos/sellos?page=" + page, {
          params: this.filtros,
        })
        .then(function(response) {
          var respuesta = response.data;
          me.sellos = respuesta.sellos;
          me.cargando = false;
        });
    },

    async getGrafica() {
      let me = this;
      me.cargando = true;
      this.filtros.transportadora_id = this.slct_transportadora
        ? this.slct_transportadora.id
        : null;
      this.filtros.sitio_id = this.slct_sitio ? this.slct_sitio.id : null;
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
      }
      axios
        .get("/api/hidrocarburos/sellos/graficas", {
          params: this.filtros,
        })
        .then(function(response) {
          me.cargando = false;
          let respuesta = response.data;
          me.cantidadDisp = respuesta.cantDisp;
          me.cantidadUsa = respuesta.cantUsa;
          me.cantidadAnu = respuesta.cantAnu;
          me.cantidadExt = respuesta.cantExt;
          me.chartData.datasets[0].data[0] = me.cantidadDisp;
          me.chartData.datasets[0].data[1] = me.cantidadUsa;
          me.chartData.datasets[0].data[2] = me.cantidadAnu;
          me.chartData.datasets[0].data[3] = me.cantidadExt;
        });
    },

    getTransportadoras() {
      let me = this;
      me.listasForms.transportadoras = [];
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: { linea_negocio_id: 2 },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      axios.get("/api/lista/37").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    resetFilter() {
      this.slct_sitio = null;
      this.slct_transportadora = null;
      this.filtros = {};
      this.getIndex();
    },

    getTipoEmpresas() {
      axios.get("/api/lista/58").then((response) => {
        this.listasForms.tipoEmpresas = response.data;
      });
    },

    getSitios() {
      let me = this;
      me.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenarModal(id, estado) {
      this.$refs.SelloEstado.llenar_modal(id, estado);
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  beforeMount() {
    this.getEstados();
    this.getTipoEmpresas();
    this.getSitios();
    this.getTransportadoras();
  },
  mounted() {
    this.getIndex();
    this.getGrafica();
  },
};
</script>
